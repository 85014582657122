var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "picking-list-container"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('van-list', {
    ref: "list",
    staticClass: "picking-list-container__list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.load
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.orders, function (order, index) {
    return _c('picking-order', {
      key: index,
      attrs: {
        "line-name": _vm.mode === 3 ? order.to_store.name : order.line ? order.line.name + '线' : '无线路',
        "datetime": order.created_at,
        "order-num": order.num,
        "client-name": _vm.mode === 3 ? '' : order.client.name,
        "picker": order.picker
      },
      on: {
        "click": function click($event) {
          return _vm.handleClick(order);
        }
      }
    });
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }