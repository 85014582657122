var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.defaultWarehouse ? _c('div', {
    staticClass: "picking-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": '仓库拣货(' + _vm.defaultWarehouse.name + ')',
      "left-text": "返回",
      "left-arrow": "",
      "right-text": "切换仓库"
    },
    on: {
      "click-right": function clickRight($event) {
        _vm.showActionSheet = true;
      }
    }
  }), _c('van-tabs', {
    staticClass: "app-main-container",
    attrs: {
      "animated": "",
      "sticky": "",
      "offset-top": "1.22667rem",
      "lazy-render": false,
      "color": "#1A6FF2",
      "title-active-color": "#1A6FF2",
      "swipeable": ""
    },
    model: {
      value: _vm.active,
      callback: function callback($$v) {
        _vm.active = $$v;
      },
      expression: "active"
    }
  }, [_c('van-tab', {
    attrs: {
      "title": "配送订单",
      "badge": _vm.total2 > 0 ? _vm.total2 : ''
    }
  }, [_c('PickingList', {
    ref: "deliveryList",
    attrs: {
      "total": _vm.total2,
      "mode": 2,
      "warehouse-id": _vm.defaultWarehouse.id
    },
    on: {
      "update:total": function updateTotal($event) {
        _vm.total2 = $event;
      },
      "clear-search_key": _vm.clearSearch_key
    }
  })], 1), _c('van-tab', {
    attrs: {
      "title": "上门订单",
      "badge": _vm.total1 > 0 ? _vm.total1 : ''
    }
  }, [_c('PickingList', {
    ref: "storeList",
    attrs: {
      "total": _vm.total1,
      "mode": 1,
      "warehouse-id": _vm.defaultWarehouse.id
    },
    on: {
      "update:total": function updateTotal($event) {
        _vm.total1 = $event;
      },
      "clear-search_key": _vm.clearSearch_key
    }
  })], 1), _c('van-tab', {
    attrs: {
      "title": "门店调货",
      "badge": _vm.total3 > 0 ? _vm.total3 : ''
    }
  }, [_c('PickingList', {
    ref: "mailList",
    attrs: {
      "total": _vm.total3,
      "mode": 3,
      "warehouse-id": _vm.defaultWarehouse.id
    },
    on: {
      "update:total": function updateTotal($event) {
        _vm.total3 = $event;
      },
      "clear-search_key": _vm.clearSearch_key
    }
  })], 1)], 1), _c('van-search', {
    staticClass: "search-bar2",
    attrs: {
      "placeholder": "请输入单号、客户名称",
      "show-action": ""
    },
    model: {
      value: _vm.search_key,
      callback: function callback($$v) {
        _vm.search_key = $$v;
      },
      expression: "search_key"
    }
  }, [_c('div', {
    attrs: {
      "slot": "action"
    },
    on: {
      "click": _vm.handleSearch
    },
    slot: "action"
  }, [_vm._v("搜索")])]), _c('div', {
    staticClass: "fixed-bottom-btn"
  }, [_c('van-button', {
    attrs: {
      "disabled": _vm.canNotPick,
      "square": "",
      "block": ""
    },
    on: {
      "click": _vm.handlePicking
    }
  }, [_vm._v(" 开始拣货 ")])], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.warehouses
    },
    on: {
      "select": _vm.chooseWarehouse
    },
    model: {
      value: _vm.showActionSheet,
      callback: function callback($$v) {
        _vm.showActionSheet = $$v;
      },
      expression: "showActionSheet"
    }
  }), _c('div', {
    staticClass: "pick-setting"
  }, [_c('van-button', {
    attrs: {
      "type": "primary",
      "square": "",
      "size": "mini"
    },
    on: {
      "click": function click($event) {
        _vm.showSettingDialog = true;
      }
    }
  }, [_c('van-icon', {
    attrs: {
      "size": "15px",
      "name": "setting-o"
    }
  })], 1)], 1), _c('my-dialog', {
    attrs: {
      "title": "设置"
    },
    on: {
      "confirm": _vm.handleSetting
    },
    model: {
      value: _vm.showSettingDialog,
      callback: function callback($$v) {
        _vm.showSettingDialog = $$v;
      },
      expression: "showSettingDialog"
    }
  }, [_c('van-field', {
    attrs: {
      "label": "拣单数量",
      "placeholder": "请输入拣单数量",
      "type": "number"
    },
    model: {
      value: _vm.pickNumber,
      callback: function callback($$v) {
        _vm.pickNumber = $$v;
      },
      expression: "pickNumber"
    }
  })], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }