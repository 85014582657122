<template>
  <div class="picking-list-container">
    <van-empty v-if="showEmpty" description="暂无数据" />
    <van-list
      ref="list"
      v-model="loading"
      class="picking-list-container__list"
      :finished="finished"
      @load="load"
    >
      <picking-order
        v-for="(order, index) in orders"
        :key="index"
        :line-name="mode === 3 ? order.to_store.name : (order.line ? order.line.name + '线' : '无线路')"
        :datetime="order.created_at"
        :order-num="order.num"
        :client-name="mode === 3 ? '' : order.client.name"
        :picker="order.picker"
        @click="handleClick(order)"
      />
    </van-list>
  </div>
</template>

<script>
import PickingOrder from './order'
import pikingApi from '@/api/picking'
export default {
  name: 'PickingList',
  components: { PickingOrder },
  props: {
    mode: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    warehouseId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      showEmpty: false,
      orders: [],
      page: 0,
      limit: 10,
      loading: false,
      finished: false,
      lastPage: 0,
      search_key: ''
    }
  },
  mounted() {
    this.$eventBus.$on('click-search', search_key => {
      this.search_key = search_key
      this.refresh()
    })
  },
  destroyed() {
    this.$eventBus.$off('click-search')
  },
  methods: {
    load() {
      this.page++
      this.getOrderList()
    },
    getOrderList() {
      const params = {
        mode: this.mode,
        warehouse_id: this.warehouseId,
        page: this.page,
        limit: this.limit,
        search_key: this.search_key
      }
      pikingApi.order(params).then((res) => {
        this.orders = this.orders.concat(res.data.list)
        this.showEmpty = this.orders.length === 0
        this.$emit('update:total', res.data.total)
        this.loading = false
        this.lastPage = res.data.last_page
        this.$emit('clear-search_key')
        if (res.data.list.length < this.limit) { this.finished = true }
      }).catch(err => {
        console.log(err)
      })
    },
    handleClick(order) {
      if (order.picker && order.picker.id !== this.$store.state.userInfo.id) {
        return
      }

      this.$router.push({
        path: '/picking-detail',
        query: {
          mode: this.mode,
          warehouse_id: this.warehouseId,
          order_id: order.id
        }
      })
    },
    refresh() {
      this.page = 0
      this.finished = false
      this.showEmpty = false
      this.orders = []
      this.$refs.list.check()
    }
  }
}
</script>

<style lang="scss" scoped>
  .picking-list-container {
    padding: 65px 15px 5px 15px;
    min-height: calc(100vh - 244px);
    margin-bottom: 50px;
  }
</style>
