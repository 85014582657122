<template>
  <div class="picking-order-container" @click="$emit('click')">
    <van-cell v-if="picker" :title="`拣货人：${picker.name}`" :value="picker.pick_datetime" />
    <van-row class="picking-order-container__header">
      <van-col span="8">{{ lineName }}</van-col>
      <van-col class="picking-order-container__right-item" span="16">{{ clientName }}</van-col>
    </van-row>
    <van-row class="picking-order-container__content">
      <van-col span="12">{{ orderNum }}</van-col>
      <van-col class="picking-order-container__right-item" span="12">{{ datetime }}</van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: 'PickingOrder',
  props: {
    lineName: {
      type: String,
      default: ''
    },
    datetime: {
      type: String,
      required: true
    },
    clientName: {
      type: String,
      default: ''
    },
    orderNum: {
      type: String,
      required: true
    },
    picker: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  @import 'src/styles/color';
  .picking-order-container {
    padding: 15px;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(48, 48, 48, 0.07);
    &__header {
      // font-size: 15px;
      padding-bottom: 10px;
    }
    &__content {
      // font-size: 15px;
    }
    &__right-item {
      text-align: right;
    }
    .van-cell {
      padding: unset;
      padding-bottom: 10px;
      margin-bottom: 10px;
      color: $amountRedColor;
      &::after {
        left: 0;
        right: 0;
      }
      &__value {
        color: $amountRedColor;
      }
    }
  }
</style>
