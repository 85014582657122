import request from '@/utils/request'

export default {

  order(params) {
    return request({
      method: 'get',
      url: 'picking/order',
      params
    })
  },
  detail(params) {
    return request({
      method: 'get',
      url: 'picking/detail',
      params
    })
  },
  goods(data) {
    return request({
      method: 'put',
      url: 'picking/goods',
      data
    })
  },
  finish(data) {
    return request({
      method: 'put',
      url: 'picking/finish',
      data
    })
  },
  cancel(data) {
    return request({
      method: 'put',
      url: 'picking/cancel',
      data
    })
  }
}

export function getNotice(params) {
  return request({
    method: 'get',
    url: 'picking/notice',
    params
  })
}
