<template>
  <div v-if="defaultWarehouse" class="picking-container">
    <my-nav-bar
      :title="'仓库拣货(' + defaultWarehouse.name + ')'"
      left-text="返回"
      left-arrow
      right-text="切换仓库"
      @click-right="showActionSheet = true"
    />
    <van-tabs
      v-model="active"
      class="app-main-container"
      animated
      sticky
      offset-top="1.22667rem"
      :lazy-render="false"
      color="#1A6FF2"
      title-active-color="#1A6FF2"
      swipeable
    >
      <van-tab title="配送订单" :badge="total2 > 0 ? total2 : ''">
        <PickingList
          ref="deliveryList"
          :total.sync="total2"
          :mode="2"
          :warehouse-id="defaultWarehouse.id"
          @clear-search_key="clearSearch_key"
        />
      </van-tab>
      <van-tab title="上门订单" :badge="total1 > 0 ? total1 : ''">
        <PickingList
          ref="storeList"
          :total.sync="total1"
          :mode="1"
          :warehouse-id="defaultWarehouse.id"
          @clear-search_key="clearSearch_key"
        />
      </van-tab>
      <van-tab title="门店调货" :badge="total3 > 0 ? total3 : ''">
        <PickingList
          ref="mailList"
          :total.sync="total3"
          :mode="3"
          :warehouse-id="defaultWarehouse.id"
          @clear-search_key="clearSearch_key"
        />
      </van-tab>
    </van-tabs>
    <van-search v-model="search_key" placeholder="请输入单号、客户名称" class="search-bar2" show-action>
      <div slot="action" @click="handleSearch">搜索</div>
    </van-search>
    <div class="fixed-bottom-btn">
      <van-button :disabled="canNotPick" square block @click="handlePicking">
        开始拣货
      </van-button>
    </div>

    <van-action-sheet v-model="showActionSheet" :actions="warehouses" @select="chooseWarehouse" />

    <div class="pick-setting">
      <van-button type="primary" square size="mini" @click="showSettingDialog = true">
        <!-- <i class="mhj-icon-shezhi" /> -->
        <van-icon size="15px" name="setting-o" />
      </van-button>
    </div>
    <my-dialog v-model="showSettingDialog" title="设置" @confirm="handleSetting">
      <van-field v-model="pickNumber" label="拣单数量" placeholder="请输入拣单数量" type="number" />
    </my-dialog>

  </div>
</template>

<script>
import warehouseApi from '@/api/warehouse'
import PickingList from './components/list'
import MyNavBar from '@/components/my-nav-bar'
import MyDialog from '@/components/my-dialog'
export default {
  name: 'Picking',
  components: { PickingList, MyNavBar, MyDialog },
  data() {
    return {
      active: 0,
      total1: 0,
      total2: 0,
      total3: 0,
      defaultWarehouse: this.$store.state.defaultWarehouse,
      canNotPick: true,
      warehouses: null,
      showActionSheet: false,
      search_key: '',
      showSettingDialog: false,
      pickNumber: this.$store.state.pickNumber
    }
  },
  watch: {
    total2() {
      if (this.active === 0) {
        this.canNotPick = this.total2 === 0
      }
    },
    total1() {
      if (this.active === 1) {
        this.canNotPick = this.total1 === 0
      }
    },
    total3() {
      if (this.active === 2) {
        this.canNotPick = this.total3 === 0
      }
    },
    active() {
      if (this.active === 0) {
        this.canNotPick = this.total2 === 0
      } else if (this.active === 1) {
        this.canNotPick = this.total1 === 0
      } else {
        this.canNotPick = this.total3 === 0
      }
    }
  },
  created() {
    // 设置默认仓库
    const params = {
      type: 1 // 存货仓
    }
    warehouseApi.list(params).then(res => {
      this.warehouses = res.data
      if (!this.defaultWarehouse) {
        const defaultWarehouse = this.warehouses[0]
        this.defaultWarehouse = defaultWarehouse
        this.$store.commit('setDefaultWarehouse', defaultWarehouse)
      }
    })
  },
  methods: {
    handlePicking() {
      const mode = this.active === 0 ? 2 : (this.active === 2 ? 3 : 1)
      this.$router.push({
        path: '/picking-detail',
        query: { mode, warehouse_id: this.defaultWarehouse.id }
      })
    },
    chooseWarehouse(warehouse) {
      this.defaultWarehouse = warehouse
      this.$store.commit('setDefaultWarehouse', warehouse)
      this.showActionSheet = false
      this.canNotPick = true
      this.total1 = this.total2 = this.total3 = 0
      this.$refs.deliveryList.refresh()
      this.$refs.storeList.refresh()
      this.$refs.mailList.refresh()
      // this.$router.go(0)
    },
    handleSearch() {
      this.$eventBus.$emit('click-search', this.search_key)
    },
    clearSearch_key() {
      // this.search_key = ''
    },
    handleSetting() {
      if (this.pickNumber < 10) {
        this.fail('拣单数量不能小于10张')
        return
      }
      this.$store.commit('setPickNumber', this.pickNumber)
    }
  }
}
</script>

<style lang="scss" scoped>
  .picking-container {
    &__footer {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      height: 50px;
    }
  }
  .pick-setting {
    position: fixed;
    z-index: 99;
    right: 0;
    bottom: 70px;
  }
</style>
