var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "picking-order-container",
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm.picker ? _c('van-cell', {
    attrs: {
      "title": "\u62E3\u8D27\u4EBA\uFF1A".concat(_vm.picker.name),
      "value": _vm.picker.pick_datetime
    }
  }) : _vm._e(), _c('van-row', {
    staticClass: "picking-order-container__header"
  }, [_c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm._v(_vm._s(_vm.lineName))]), _c('van-col', {
    staticClass: "picking-order-container__right-item",
    attrs: {
      "span": "16"
    }
  }, [_vm._v(_vm._s(_vm.clientName))])], 1), _c('van-row', {
    staticClass: "picking-order-container__content"
  }, [_c('van-col', {
    attrs: {
      "span": "12"
    }
  }, [_vm._v(_vm._s(_vm.orderNum))]), _c('van-col', {
    staticClass: "picking-order-container__right-item",
    attrs: {
      "span": "12"
    }
  }, [_vm._v(_vm._s(_vm.datetime))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }